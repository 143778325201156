.body {
  &__body {
    padding-left: 300px !important;
    padding-top: 120px !important;
  }
  @media (min-width: 0px) and (max-width: 970px) {
    &__body {
      padding-left: 50px !important;
      padding-top: 0 !important;
    }
  }
}

.cards {
  &__cards {
    border-style: solid;
    border-width: 0.01em;
    border-color: #373b3e;
    padding: 30px;
    margin: 50px;
    color: #000;
  }
  @media (min-width: 0px) and (max-width: 970px) {
    &__cards {
      margin-left: 0;
      padding: 5px;
    }
  }
}

.typeWriter {
  transform: rotate(0deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(0deg);
  /* Firefox */
  -moz-transform: rotate(0deg);
  /* IE */
  -ms-transform: rotate(0deg);
  /* Opera */
  -o-transform: rotate(0deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
}

.contact {
  color: #ad9e9e;
}

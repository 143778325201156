.body {
  &__body {
    margin: 20px;
    padding-left: 300px !important;
    padding-top: 120px !important;
  }
  @media (min-width: 0px) and (max-width: 970px) {
    &__body {
      padding-left: 50px !important;
      padding-top: 0 !important;
    }
  }
}

.summary {
  &__summary {
    padding: 30px;
    margin: 50px;
  }
  @media (min-width: 0px) and (max-width: 970px) {
    &__summary {
      margin: 0;
    }
  }
}

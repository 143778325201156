.backgroundImage {
  &__backgroundImage {
    max-width: 100%;
    max-height: 100%;
  }
  @media (min-width: 0px) and (max-width: 970px) {
    &__backgroundImage {
      display: none;
    }
  }
}

.background {
  @media (min-width: 0px) and (max-width: 970px) {
    &__background {
      display: none;
    }
  }
}
.headOfTheTitles {
  text-decoration: underline var(--bs-code-color);
  margin-top: 20px;
}
.body {
  &__body {
    position: fixed;
    background: #373b3e;
    color: #dee2e6;
    top: 0;
    bottom: 0;
    width: 300px !important;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 0px) and (max-width: 970px) {
    &__body {
      display: none;
    }
  }
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.typeWriter {
  transform: rotate(0deg);
  color: black;
  margin-left: 20px;
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(0deg);
  /* Firefox */
  -moz-transform: rotate(0deg);
  /* IE */
  -ms-transform: rotate(0deg);
  /* Opera */
  -o-transform: rotate(0deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  @media (min-width: 970px) and (max-width: 2970px) {
    &__phone {
      display: none;
    }
  }
}

.leftNavigation {
  &__leftNavigation {
    padding: 10px;
    background: #373b3e;
    margin: 10px;
    margin-right: 45px;
    list-style: none;
    border-style: solid;
    border-width: 0.01em;
    border-color: white;
    color: #dee2e6;
    &:hover {
      background-color: white;
      opacity: 0.6;
      color: black;
      transition: 1s;
    }
  }
  @media (min-width: 0px) and (max-width: 970px) {
    &__leftNavigation {
      list-style: none;
      background-color: #373b3e;
      display: inline-flex;
      width: 300px;
    }
  }
  @media (min-width: 970px) and (max-width: 2970px) {
    &__leftNavigation__phone {
      display: none;
      list-style: none;
    }
  }
  @media (min-width: 970px) and (max-width: 2970px) {
    &__leftNavigation__inline {
      display: none;
    }
  }
}

.contact {
  padding: 5px;
  margin: 5px;
  margin-left: 28px;
  display: inline-flex;
  color: black;
  text-align: center;
  align-items: center;
  &__phone {
    margin-left: 20px;
    display: inline-flex;
    list-style: none;
    color: black;
    
  }
  @media (min-width: 970px) and (max-width: 2970px) {
    &__phone {
      display: none;
    }
  }
}

.rowTextDecoration {
  font-size: 12px;
  text-align: center;
  align-items: center;
  letter-spacing: 0.5px;
}

.sidebarCol {
  border: 2px solid gray;
  border-radius: 15px;
  padding: 5px;
  margin: 2px;
}

body {
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  color: black !important;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New;
}

.licance {
  @media (min-width: 0px) and (max-width: 970px) {
    display: none;
  }
  @media (min-width: 970px) and (max-width: 2970px) {
    bottom: 10px;
    right: 10px;
    position: fixed;
    color: black;
    font-weight: lighter;
    font-size: 12px;
  }
}
